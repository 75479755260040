import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    // Tooltip,
    Divider,
    CardActions,
    Button,
    Collapse,
    IconButton
} from '@material-ui/core';
// import AddCircleIcon from '@material-ui/icons/AddCircle'
// import Delete from '@material-ui/icons/Delete'
import { Autocomplete } from '@material-ui/lab';
import useBreakpoints from '../../../styles/useBreakpoints';

import epss from '../../../data/epss'
import fondospension from '../../../data/fondosPension'
import tipoAfiliaciones from '../../../data/tipoAfiliaciones';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



function InformacionPersonal(props) {
    const point = useBreakpoints();
    const { theme, setMessage, setSnack, setSeverity, setInformacionPersonal } = props;
    const [errores, setErrores] = useState(Array(16).fill(false));
    const [erroresFamilia, setErroresFamilia] = useState(Array(1).fill(Array(2).fill(false)));
    // const [index, setIndex] = props.index;
    const [completado, setCompletado] = props.datosCompletos;
    const [cirugia, setCirugia] = useState(null);
    const [tipocirugia, setTipoCirugia] = useState('');
    const [medicamento, setMedicamento] = useState(null);
    const [tipomedicamento, setTipoMedicamento] = useState('');
    const [nombrefamiliar, setNombreFamiliar] = useState(Array(1).fill(''));
    const [parentesco, setParentesco] = useState(Array(1).fill(''));
    const [telefonofamiliar, setTelefonoFamiliar] = useState(Array(1).fill(''));
    const [nombrereferencia, setNombreReferencia] = useState('');
    const [relacionreferencia, setRelacionReferencia] = useState('');
    const [telefonoreferencia, setTelefonoReferencia] = useState('');
    const [nombrereferencia2, setNombreReferencia2] = useState('');
    const [relacionreferencia2, setRelacionReferencia2] = useState('');
    const [telefonoreferencia2, setTelefonoReferencia2] = useState('');
    const [estadoSalud, setEstadoSalud] = useState('');
    const [disponibilidadtiempo, setDisponibilidadTiempo] = useState(null);
    // const [salario, setSalario] = useState(null);
    const [eps, setEps] = useState(null);
    const [fondopensiones, setFondoPensiones] = useState(null);
    const [estadoafiliacion, setEstadoAfiliacion] = useState(null);
    const [tipoAfiliacion, setTipoAfiliacion] = useState(null);

    const validar = () => {
        let errorDatos = false;
        // completado[3] = true
        // setCompletado([...completado])
        if (cirugia === null) {
            errorDatos = true;
            errores[0] = true;
        }
        if (cirugia === 'Sí' && tipocirugia === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (medicamento === null) {
            errorDatos = true;
            errores[2] = true;
        }
        if (medicamento === 'Sí' && tipomedicamento === '') {
            errorDatos = true;
            errores[3] = true;
        }
        if (nombrereferencia === '') {
            errorDatos = true;
            errores[4] = true;
        }
        if (relacionreferencia === '') {
            errorDatos = true;
            errores[5] = true;
        }
        if (telefonoreferencia === '') {
            errorDatos = true;
            errores[6] = true;
        }
        if (nombrereferencia2 === '') {
            errorDatos = true;
            errores[7] = true;
        }
        if (relacionreferencia2 === '') {
            errorDatos = true;
            errores[8] = true;
        }
        if (telefonoreferencia2 === '') {
            errorDatos = true;
            errores[9] = true;
        }
        // if (disponibilidadtiempo === null) {
        //     errorDatos = true;
        //     errores[10] = true;
        // }
        // if (salario === null) {
        //     errorDatos = true;
        //     errores[11] = true;
        // }
        if (eps === null) {
            errorDatos = true;
            errores[12] = true;
        }
        if (fondopensiones === null) {
            errorDatos = true;
            errores[13] = true;
        }
        if (estadoafiliacion === null) {
            errorDatos = true;
            errores[14] = true;
        }
        if (tipoAfiliacion === null) {
            errorDatos = true;
            errores[15] = true;
        }
        if (estadoSalud === '') {
            errorDatos = true;
            errores[16] = true;
        }        

        for (let i = nombrefamiliar.length - 1; i >= 0; i--) {
            if (nombrefamiliar[i] === '') {
                errorDatos = true;
                erroresFamilia[i][0] = true;
            }
            if (parentesco[i] === '') {
                errorDatos = true;
                erroresFamilia[i][1] = true;
            }
        }
        if (errorDatos) {
            setErrores([...errores]);
            setErroresFamilia([...erroresFamilia]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }

        else {
            setInformacionPersonal(
                {
                    'tuvo_cirugia': cirugia === 'Sí',
                    'que_cirugia': tipocirugia,
                    'toma_medicamento': medicamento === 'Sí',
                    'que_medicamento': tipomedicamento,
                    'estado_salud': estadoSalud,
                    'nucleo_familiar': nombrefamiliar.map((v, i) => (
                        {
                            'nombre_familiar': nombrefamiliar[i],
                            'parentesco': parentesco[i],
                            'telefono_familiar': telefonofamiliar[i]
                        }
                    )),
                    'nombre_referencia_1': nombrereferencia,
                    'relacion_referencia_1': relacionreferencia,
                    'telefono_referencia_1': telefonoreferencia,
                    'nombre_referencia_2': nombrereferencia2,
                    'relacion_referencia_2': relacionreferencia2,
                    'telefono_referencia_2': telefonoreferencia2,
                    'disponibilidad_tiempo': disponibilidadtiempo === 'Por horas' ? 0 : (disponibilidadtiempo === 'Tiempo completo' ? 1 : (disponibilidadtiempo === 'Medio tiempo' ? 2 : 3)),
                    // 'aspiracion_salarial': salario === 'Menos de $877.803' ? 0 : (salario === '$877.803 - $1.000.000' ? 1 : (salario === '$1.000.000 - $1.500.000' ? 2 : 3)),
                    'eps': epss.filter(e => e.eps === eps)[0].cod,
                    'pension': fondospension.filter(p => p.pension === fondopensiones)[0].cod,
                    'esta_afiliado': estadoafiliacion === 'Activo',
                    'tipo_afiliacion': tipoAfiliaciones.filter(t => t.tipo === tipoAfiliacion)[0].cod
                }
            )
            completado[3] = true
            setCompletado([...completado])
            setMessage('Los datos de información personal han sido diligenciados correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);

        }
    }

    // function addFamilia() {
    //     setNombreFamiliar(nombrefamiliar.concat(''));
    //     setParentesco(parentesco.concat(''));
    //     setTelefonoFamiliar(telefonofamiliar.concat(''));

    //     setIndex(index + 1);
    //     setErroresFamilia(erroresFamilia.concat([Array(3).fill(false)]))
    // }

    // function removeFamilia() {
    //     nombrefamiliar.splice(index, 1);
    //     setNombreFamiliar(nombrefamiliar);
    //     parentesco.splice(index, 1);
    //     setParentesco(parentesco);
    //     telefonofamiliar.splice(index, 1);
    //     setTelefonoFamiliar(telefonofamiliar);

    //     setErrores(erroresFamilia);
    //     setIndex(index - 1);

    // }


    const limpiar = () => {
        setCirugia(null);
        setTipoCirugia('');
        setMedicamento(null);
        setTipoMedicamento('');
        setNombreFamiliar(Array(1).fill(''));
        setParentesco(Array(1).fill(''));
        setTelefonoFamiliar(Array(1).fill(''));
        setNombreReferencia('');
        setRelacionReferencia('');
        setTelefonoReferencia('');
        setNombreReferencia2('');
        setRelacionReferencia2('');
        setTelefonoReferencia2('');
        setEstadoSalud('');
        setDisponibilidadTiempo(null);
        // setSalario(null);
        setEps(null);
        setFondoPensiones(null);
        setEstadoAfiliacion(null);
        setTipoAfiliacion(null);

        setErrores(Array(18).fill(false));
        setErroresFamilia(Array(1).fill(Array(2).fill(false)));
    }

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>3. Información Personal: </Typography>
                }
                action={
                    <IconButton
                        disabled={completado[3] === false}
                        onClick={() => { completado[3] = false; setCompletado([...completado]) }}
                        color='secondary'
                        aria-label='add'
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={completado[1] === true && completado[3] === false} mountOnEnter unmountOnExit>
                <CardContent>
                    <Typography variant='body2' style={{ marginBottom: theme.spacing(1), marginLeft: theme.spacing(2) }}><span style={{ color: '#e53935' }}>*</span> Estos campos son obligatorios</Typography>
                    <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />
                    <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Historial médico:</Typography>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>¿Le han practicado alguna cirugía?: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={cirugia}
                                onChange={(e, v) => { setCirugia(v); setTipoCirugia(''); errores[0] = false; setErrores([...errores]) }}
                                options={
                                    [
                                        "Sí", "No"
                                    ].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[0]}
                                        helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[1]].includes(true) && !errores[0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>¿Cuál?: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                disabled={cirugia === null || cirugia === 'No'}
                                value={tipocirugia}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoCirugia(e.target.value); errores[1] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[1]}
                                helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[0]].includes(true) && !errores[1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>¿Actualmente toma algún medicamento?: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={medicamento}
                                onChange={(e, v) => { setMedicamento(v); setTipoMedicamento(''); errores[2] = false; setErrores([...errores]) }}
                                options={
                                    [
                                        "Sí", "No"
                                    ].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[2]}
                                        helperText={errores[2] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[3]].includes(true) && !errores[2] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>¿Cuál?: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                disabled={medicamento === null || medicamento === 'No'}
                                value={tipomedicamento}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoMedicamento(e.target.value); errores[3] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[3]}
                                helperText={errores[3] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[2]].includes(true) && !errores[3] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant="body1">
                                Comente sobre su estado de salud actual (Recuerde ser específico): <span style={{ color: '#e53935' }}>*</span>
                            </Typography>
                            <TextField
                                value={estadoSalud}
                                onChange={(e) => { setEstadoSalud(e.target.value); errores[16] = false; setErrores([...errores]); }}
                                size="small"
                                variant="outlined"
                                fullWidth
                                error={errores[16]}
                                helperText={errores[16] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Referencia Familiar:</Typography>
                        {/* <Typography variant='body2' color='secondary' style={{ fontSize: 14, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Por favor, mencione a las personas que viven con usted; o bien, mínimo dos referencias familiares.</Typography> */}
                    </div>

                    {
                        nombrefamiliar.map((v, i) => (
                            <React.Fragment key={"Nucleo"}>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    spacing={4}
                                >
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1'>Nombre completo: <span style={{ color: '#e53935' }}>*</span></Typography>

                                        <TextField
                                            value={nombrefamiliar[i]}
                                            onChange={(e) => { nombrefamiliar[i] = e.target.value; setNombreFamiliar([...nombrefamiliar]); erroresFamilia[i][0] = false; setErroresFamilia([...erroresFamilia]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            autoFocus
                                            error={erroresFamilia[i][0]}
                                            helperText={erroresFamilia[i][0] ? 'Este campo no puede quedar vacío' : null}
                                            style={{ marginBottom: [erroresFamilia[i][1]].includes(true) && !erroresFamilia[i][0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />

                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1'>Parentesco: <span style={{ color: '#e53935' }}>*</span></Typography>

                                        <TextField
                                            value={parentesco[i]}
                                            onChange={(e) => { parentesco[i] = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, ''); setParentesco([...parentesco]); erroresFamilia[i][1] = false; setErroresFamilia([...erroresFamilia]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            autoFocus
                                            error={erroresFamilia[i][1]}
                                            helperText={erroresFamilia[i][1] ? 'Este campo no puede quedar vacío' : null}
                                            style={{ marginBottom: [erroresFamilia[i][0]].includes(true) && !erroresFamilia[i][1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />

                                    </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <Typography variant='body1'>Teléfono del familiar:</Typography>

                                        <TextField
                                            value={telefonofamiliar[i]}
                                            onChange={(e) => { if (e.target.value.length <= 10) telefonofamiliar[i] = e.target.value.replace(/[^0-9]/g, ''); setTelefonoFamiliar([...telefonofamiliar]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            style={{ marginBottom: [errores[i][0], errores[i][1]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />

                                    </Grid>
                                </Grid>
{/* 
                                <CardActions style={{ justifyContent: 'center', paddingRight: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
                                    <Tooltip title='Añadir familiar'>
                                        <div>
                                            <IconButton
                                                disabled={nombrefamiliar.length >= 10}
                                                onClick={() => addFamilia()}
                                                color='primary'
                                                aria-label='add'
                                            >
                                                <AddCircleIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    <div>
                                        <IconButton
                                            disabled={nombrefamiliar.length === 1}
                                            onClick={() => removeFamilia(index)}
                                            color='secondary'
                                            aria-label='add'
                                        >
                                            <Delete />
                                        </IconButton>
                                    </div>
                                </CardActions> */}

                            </React.Fragment>
                        ))
                    }


                    <div>
                        <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Referencias Personales:</Typography>
                        <Typography variant='body2' color='secondary' style={{ fontSize: 14, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Por favor, mencione dos referencias personales.</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Nombre completo: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={nombrereferencia}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia(e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, '')); errores[4] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[4]}
                                helperText={errores[4] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[5], errores[6]].includes(true) && !errores[4] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Relación: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={relacionreferencia}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia(e.target.value); errores[5] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[5]}
                                helperText={errores[5] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[4], errores[6]].includes(true) && !errores[5] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1'>Teléfono: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={telefonoreferencia}
                                onChange={(e) => { if (e.target.value.length <= 10) setTelefonoReferencia(e.target.value.replace(/[^0-9]/g, '')); errores[6] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[6]}
                                helperText={errores[6] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[4], errores[5]].includes(true) && !errores[6] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Nombre completo: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={nombrereferencia2}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia2(e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g, '')); errores[7] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[7]}
                                helperText={errores[7] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[8], errores[9]].includes(true) && !errores[7] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1'>Relación: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={relacionreferencia2}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia2(e.target.value); errores[8] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                                error={errores[8]}
                                helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[7], errores[9]].includes(true) && !errores[8] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1'>Teléfono: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <TextField
                                value={telefonoreferencia2}
                                onChange={(e) => { if (e.target.value.length <= 10) setTelefonoReferencia2(e.target.value.replace(/[^0-9]/g, '')); errores[9] = false; setErrores([...errores]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                error={errores[9]}
                                helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[7], errores[8]].includes(true) && !errores[9] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    {/* <div>
                        <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Información para el CNC:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'> ¿Qué disponibilidad de tiempo tiene para desarrollar actividades en CNC?: <span style={{ color: '#e53935' }}>* </span></Typography>

                            <Autocomplete
                                value={disponibilidadtiempo}
                                onChange={(e, v) => { setDisponibilidadTiempo(v); errores[10] = false; setErrores([...errores]) }}
                                options={
                                    ['Por horas',
                                        'Tiempo completo',
                                        'Medio tiempo',
                                        'Solo fines de semana'].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[10]}
                                        helperText={errores[10] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[11]].includes(true) && !errores[10] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'> ¿Cuál es su aspiración salarial?: <span style={{ color: '#e53935' }}>* </span></Typography>

                            <Autocomplete
                                value={salario}
                                onChange={(e, v) => { setSalario(v); errores[11] = false; setErrores([...errores]) }}
                                options={
                                    ['Menos de $877.803',
                                        '$877.803 - $1.000.000',
                                        '$1.000.000 - $1.500.000',
                                        'Sobre $1.500.000'].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[11]}
                                        helperText={errores[11] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[10]].includes(true) && !errores[11] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid> 
                    </Grid> */}

                    <div>
                        <Typography variant='body1' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Información seguridad social:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>EPS: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={eps}
                                onChange={(e, v) => { setEps(v); errores[12] = false; setErrores([...errores]) }}
                                options={
                                    epss.map(option => option.eps)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[12]}
                                        helperText={errores[12] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[13]].includes(true) && !errores[12] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Fondo de pensión: <span style={{ color: '#e53935' }}>*</span></Typography>

                            <Autocomplete
                                value={fondopensiones}
                                onChange={(e, v) => { setFondoPensiones(v); errores[13] = false; setErrores([...errores]) }}
                                options={
                                    fondospension.map(option => option.pension)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[13]}
                                        helperText={errores[13] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[12]].includes(true) && !errores[13] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />

                                )}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Estado de afiliación: <span style={{ color: '#e53935' }}>* </span></Typography>

                            <Autocomplete
                                value={estadoafiliacion}
                                onChange={(e, v) => { setEstadoAfiliacion(v); errores[14] = false; setErrores([...errores]) }}
                                options={
                                    ['Activo',
                                        'Inactivo'].map(option => option)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[14]}
                                        helperText={errores[14] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[15]].includes(true) && !errores[14] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'>Tipo de afiliación: <span style={{ color: '#e53935' }}>* </span></Typography>

                            <Autocomplete
                                value={tipoAfiliacion}
                                onChange={(e, v) => { setTipoAfiliacion(v); errores[15] = false; setErrores([...errores]) }}
                                options={
                                    tipoAfiliaciones.map(option => option.tipo)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        error={errores[15]}
                                        helperText={errores[15] ? 'Este campo no puede quedar vacío' : null}
                                        style={{ marginBottom: [errores[14]].includes(true) && !errores[15] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                )}
                            />

                        </Grid>
                    </Grid>

                </CardContent>

                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />

                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>

                    <Button
                        onClick={() => limpiar()}
                        variant='contained'
                        color='secondary'
                    >
                        Limpiar
                    </Button>
                    <Button
                        onClick={() => {
                            validar();
                        }}
                        variant='contained'
                        color='primary'
                    >
                        Continuar
                    </Button>
                </CardActions>
            </Collapse>
        </Card>
    );
}

export default InformacionPersonal;